export default {
    'page.menu-title.default': 'Sakai',
    'page.menu-title.auth.login': 'Login',
    'page.menu-title.page.notfound': 'Not found',
    'page.menu-title.page.access-denied': 'Access denied',
    'page.menu-title.page.access-error': 'Error',
    'page.menu-title.detail.dashboard': 'Dashboard',
    'page.menu-title.detail.landing': 'Landing',
    'page.menu-title.detail.documentation': 'Documentation',
    'page.menu-title.detail.page-empty': 'Empty',
    'page.menu-title.detail.page-crud': 'CRUD',
    'page.menu-title.detail.uikit-formlayout': 'FormLayout',
    'page.menu-title.detail.uikit-input': 'Input',
    'page.menu-title.detail.uikit-button': 'Button',
    'page.menu-title.detail.uikit-table': 'Table',
    'page.menu-title.detail.uikit-list': 'List',
    'page.menu-title.detail.uikit-tree': 'Tree',
    'page.menu-title.detail.uikit-panel': 'Panel',
    'page.menu-title.detail.uikit-overlay': 'Overlay',
    'page.menu-title.detail.uikit-media': 'Media',
    'page.menu-title.detail.uikit-message': 'Message',
    'page.menu-title.detail.uikit-file': 'File',
    'page.menu-title.detail.uikit-menu': 'Menu',
    'page.menu-title.detail.uikit-charts': 'Charts',
    'page.menu-title.detail.uikit-misc': 'Misc',
    'page.menu-title.detail.uikit-timeline': 'Timeline'
};
